<!--nav data-cy="navbar" class="navbar navbar-dark navbar-expand-md" style="background-color: #72a38f;"-->
<nav data-cy="navbar" class="navbar navbar-dark bg-info navbar-expand-md">
  <a *ngIf="isAuthenticated()" class="navbar-brand logo" routerLink="/home" (click)="collapseNavbar()">
    <!--span class="logo-img"></span-->
    <span class="navbar-title">SIG-RSU</span>
    <!--span class="navbar-version">{{ version }}</span-->
  </a>
  <a *ngIf="!isAuthenticated()" class="navbar-brand logo" routerLink="/home" (click)="collapseNavbar()">
    <!--span class="logo-img"></span-->
    <span class="navbar-title">SIG-RSU</span>
    <!--span class="navbar-version">{{ version }}</span-->
  </a>
  <a class="navbar-toggler d-lg-none" href="javascript:void(0);" data-toggle="collapse" data-target="#navbarResponsive"
    aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbar()">
    <fa-icon icon="bars"></fa-icon>
  </a>
  <div class="navbar-collapse collapse" id="navbarResponsive" [ngbCollapse]="isNavbarCollapsed"
    [ngSwitch]="isAuthenticated()">
    <ul class="navbar-nav ml-auto">
      <li *ngSwitchCase="true" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="/home" (click)="collapseNavbar()">
          <fa-icon icon="home"></fa-icon>
          <span>
            <span>Home</span>
          </span>
        </a>
      </li>

      <!-- Administrateur Agence de collecte -->
      <li *jhiHasAnyAuthority="'ADMIN_AGENCE'" class="nav-item" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="superviseur-agence" (click)="collapseNavbar()">
          <span>
            <span>Superviseur</span>
          </span>
        </a>
      </li>

      <li *jhiHasAnyAuthority="'ADMIN_AGENCE'" class="nav-item" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="controleur" (click)="collapseNavbar()">
          <span>
            <span>Contr&ocirc;leur</span>
          </span>
        </a>
      </li>

      <li *jhiHasAnyAuthority="'ADMIN_AGENCE'" class="nav-item" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="enqueteur" (click)="collapseNavbar()">
          <span>
            <span>Enqu&ecirc;teur</span>
          </span>
        </a>
      </li>

      <li *jhiHasAnyAuthority="'ADMIN_AGENCE'" class="nav-item" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="equipe-enquete" (click)="collapseNavbar()">
          <span>
            <span>&Eacute;quipes</span>
          </span>
        </a>
      </li>

      <li *jhiHasAnyAuthority="'ADMIN_AGENCE'" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
          <span>Collecte des données</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'C', 'zdcs-affectees']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <span>Zones de dénombrement</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'U', 'menage-enquetes-agence']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <span>Ménages enquêtés</span>
            </a>
          </li>
        </ul>
      </li>

      <li *jhiHasAnyAuthority="'ADMIN_AGENCE'" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
          <span>Enumération</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'E', 'zdcs-affectees']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <span>Zones de dénombrement</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/enumeration/concession-enumeree" (click)="collapseNavbar()"
              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <span>Concessions énumerées</span>
            </a>
          </li>
        </ul>
      </li>

      <li *jhiHasAnyAuthority="'ADMIN_AGENCE'" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
          <span>Dénombrement</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'D', 'zdcs-affectees']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <span>Zones de dénombrement</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/denombrement/cas-agence']" (click)="collapseNavbar()"
              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <span>
                <span>Ménages dénombrés</span>
              </span>
            </a>
          </li>
        </ul>
      </li>
      <!-- Fin Administrateur Agence de collecte -->

      <!-- Superviseur Agence de collecte -->
      <li *jhiHasAnyAuthority="'SUPERVISEUR_AGENCE'" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
          <span>Collecte des données</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'C', 'equipe-superviseur']"
              (click)="collapseNavbar()">
              <span>&Eacute;quipes</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'C', 'zdcs-affectees']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <span>Zones de dénombrement</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'U', 'menage-enquetes-superviseur']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <span>
                <span>Ménages enquêtés</span>
              </span>
            </a>
          </li>
        </ul>
      </li>

      <li *jhiHasAnyAuthority="'SUPERVISEUR_AGENCE'" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
          <span>Dénombrement</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'D', 'equipe-superviseur']"
              (click)="collapseNavbar()">
              <span>&Eacute;quipes</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'D', 'zdcs-affectees']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <span>Zones de dénombrement</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/denombrement/cas-superviseur']" (click)="collapseNavbar()"
              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <span>Ménages dénombrés</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- Fin Superviseur Agence de collecte -->

      <!-- Controleur Agence de collecte -->
      <li *jhiHasAnyAuthority="'CONTROLEUR_AGENCE'" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
          <span>Collecte des données</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="nav-link" class="dropdown-item" [routerLink]="['/menage-enquete', 'C', 'zdcs-affectees']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <span>Zones de dénombrement</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'C', 'enqueteur-controleur']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <span>Enqu&ecirc;teurs</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'U', 'menage-enquetes-controleur']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <span>Ménages enquêtés</span>
            </a>
          </li>
        </ul>
      </li>
      <li *jhiHasAnyAuthority="'CONTROLEUR_AGENCE'" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
            <span>&Eacute;num&eacute;ration</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="nav-link" class="dropdown-item" [routerLink]="['/menage-enquete', 'E', 'zdcs-affectees']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <span>Zones de dénombrement</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'E', 'enqueteur-controleur']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <span>Enqu&ecirc;teurs</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/enumeration', 'C', 'concession-enumeree-controleur']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <span>Concession &eacute;num&eacute;r&eacute;es</span>
            </a>
          </li>
        </ul>
      </li>
      <li *jhiHasAnyAuthority="'CONTROLEUR_AGENCE'" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
            <span>D&eacute;nombrement</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="nav-link" class="dropdown-item" [routerLink]="['/menage-enquete', 'D', 'zdcs-affectees']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <span>Zones de dénombrement</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'D', 'enqueteur-controleur']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <span>Enqu&ecirc;teurs</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/denombrement/cas-controleur']" (click)="collapseNavbar()"
              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <span>M&eacute;nages d&eacute;nombr&eacute;s</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- Fin Controleur Agence de collecte -->

      <!-- Administrateur et superviseur ONG -->

      <li *jhiHasAnyAuthority="'ADMIN_ONG'" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
            <span>Zones AG</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="nav-link" class="dropdown-item" [routerLink]="['/localite/download-localite-quota']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <span>Télécharger localités avec quota</span>
            </a>
          </li>
          <li>
            <a class="nav-link" class="dropdown-item" [routerLink]="['creationzd']"
              (click)="collapseNavbar()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <span>Créer ZD</span>
            </a>
          </li>
        </ul>
      </li>

      <li *jhiHasAnyAuthority="['ADMIN_ONG', 'SUPERVISEUR_ONG']" class="nav-item" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="critere-pauvrete/ong" (click)="collapseNavbar()">
          <span>Critères de pauvrete</span>
        </a>
      </li>

      <li *jhiHasAnyAuthority="'ADMIN_ONG'" class="nav-item" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="superviseur-ong" (click)="collapseNavbar()">
            <span>Superviseur</span>
        </a>
      </li>

      <li *jhiHasAnyAuthority="['ADMIN_ONG']" class="nav-item" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="animateur" (click)="collapseNavbar()">
            <span>Animateur</span>
        </a>
      </li>

      <li *jhiHasAnyAuthority="['ADMIN_ONG']" class="nav-item" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="equipe-animateur" (click)="collapseNavbar()">
          <span>&Eacute;quipes</span>
        </a>
      </li>

      <li *jhiHasAnyAuthority="['ADMIN_ONG', 'SUPERVISEUR_ONG']" class="nav-item" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="ciblagecom" (click)="collapseNavbar()">
          <span>Ciblage communautaire</span>
        </a>
      </li>
      <!-- Fin Administrateur ONG -->

      <!-- Consultation -->
      <li *jhiHasAnyAuthority="'CONSULTATION'" class="nav-item" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" [routerLink]="['/menage-enquete', 'R', 'menage-enquetes']" (click)="collapseNavbar()">
          <span>
            <span>Ménages enquêtés - rural</span>
          </span>
        </a>
      </li>
      <li *jhiHasAnyAuthority="'CONSULTATION'" class="nav-item" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" [routerLink]="['/menage-enquete', 'U', 'menage-enquetes']" (click)="collapseNavbar()">
          <span>
            <span>Ménages enquêtés - urbain</span>
          </span>
        </a>
      </li>

      <li *jhiHasAnyAuthority="'CONSULTATION'" class="nav-item" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="ciblagecom" (click)="collapseNavbar()">
          <span>Ciblage communautaire</span>
        </a>
      </li>
      <!-- Fin Consultation -->

      <!-- Phone call -->
      <li *jhiHasAnyAuthority="['PHONE_CALL_ENTRE_EST', 'PHONE_CALL_ENTRE_OUEST']" class="nav-item"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="menage-phone-call" (click)="collapseNavbar()">
          <span>
            <span>Ménages</span>
          </span>
        </a>
      </li>
      <!-- Fin Phone call -->

      <li *jhiHasAnyAuthority="'ROLE_USER'" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">

        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
            <span>Collecte des données</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li *jhiHasAnyAuthority="'ROLE_ADMIN'">
            <a class="dropdown-item" routerLink="superviseur-agence" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                <span>Superviseur</span>
            </a>
          </li>
          <li *jhiHasAnyAuthority="'ROLE_ADMIN'">
            <a class="dropdown-item" routerLink="controleur" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Contr&ocirc;leur</span>
            </a>
          </li>
          <li *jhiHasAnyAuthority="'ROLE_ADMIN'">
            <a class="dropdown-item" routerLink="enqueteur" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Enqueteur</span>
            </a>
          </li>
          <li *jhiHasAnyAuthority="'ROLE_ADMIN'">
            <a class="dropdown-item" routerLink="equipe-enquete" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>&Eacute;quipes</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'R', 'menage-enquetes']"
              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Ménages enquêtés</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/menage-enquete', 'U', 'menage-enquetes-rsu']"
              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Ménages enquêtés - urbain</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/enumeration/concession-enumeree" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                <span>Concessions énumerées</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="denombrement" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                <span>Dénombrement</span>
            </a>
          </li>
        </ul>
      </li>

      <li *jhiHasAnyAuthority="'ROLE_USER'" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
            <span>Ciblage communautaire</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="dropdown-item" routerLink="creationzd" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Création ZD</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="ciblagecom" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Ciblage communautaire</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="animateur" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Animateur</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="superviseur-ong" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Superviseur</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="equipe-animateur" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>&Eacute;quipes</span>
            </a>
          </li>
        </ul>
      </li>

      <li *jhiHasAnyAuthority="'ROLE_USER'" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
            <span>R&eacute;clamation</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="dropdown-item" routerLink="reclamation" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Traiter r&eacute;clamation</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="utilisateur-apli-plainte" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Utilisateurs application mobile</span>
            </a>
          </li>
        </ul>
      </li>

      <li *jhiHasAnyAuthority="['ROLE_DOP']" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
            <span>Ciblage g&eacute;ographique</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="dropdown-item" routerLink="comite-departemental" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Importer comit&eacute; d&eacute;partemental</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="export-canevas-comite-depart" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Exporter canevas comit&eacute; d&eacute;partemental</span>
            </a>
          </li>
        </ul>
      </li>

      <li *jhiHasAnyAuthority="['ROLE_ADMIN']" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
            <span>Ciblage g&eacute;ographique</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="dropdown-item" routerLink="region" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Région</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="province" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Province</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="commune" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Commune</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="localite" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Localité</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="critere-pauvrete/ong" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Critere pauvreté</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="zone-controle" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Zone de contrôle</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="zone-decoupage" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Zone de découpage</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/zone-decoupage/import-zone-decoupage" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Importer zones de découpage</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="comite-departemental" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Importer comit&eacute; d&eacute;partemental</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="export-canevas-comite-depart" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Exporter canevas comit&eacute; d&eacute;partemental</span>
            </a>
          </li>
        </ul>
      </li>

      <li *jhiHasAnyAuthority="['ROLE_ADMIN', 'ROLE_DOP']" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
            <span>PMT</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="dropdown-item" routerLink="variable-pmt" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Variable PMT</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="type-decoupage" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Type de découpage PMT</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="modele-pmt" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Modèle PMT</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="formule-pmt" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Formule PMT</span>
            </a>
          </li>
          <li *jhiHasAnyAuthority="'ROLE_ADMIN'">
            <a class="dropdown-item" routerLink="calcul-pmt" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Cacul scores PMT</span>
            </a>
          </li>
        </ul>
      </li>

      <li *jhiHasAnyAuthority="'ROLE_ADMIN'" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu"
          data-cy="entity">
            <span>Param&eacute;trage</span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a class="dropdown-item" routerLink="nature-piece-identite" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Nature Piece Identite</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="critere-pauvrete" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Critères de base</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="agence-collecte" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Agence de collecte</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="ong" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>ONG</span>
            </a>
          </li>
          <!--li>
            <a class="dropdown-item" routerLink="ong/liste-affectation-province" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Affecter provinces &agrave; ONG</span>
            </a>
          </li-->
          <li>
            <a class="dropdown-item" routerLink="role-enqueteur" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>R&ocirc;le Enqu&ecirc;teur</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="role-animateur" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>R&ocirc;le animateur</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="template" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Template file</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="enumeration" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Enumération ménage</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="apurement" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
              <span>Import. apurement dénombrement</span>
            </a>
          </li>
        </ul>
      </li>
      <li *jhiHasAnyAuthority="'ROLE_ADMIN'" ngbDropdown class="nav-item dropdown pointer" display="dynamic"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="admin-menu"
          data-cy="adminMenu">
          <span>
            <fa-icon icon="users-cog"></fa-icon>
            <span>Administration</span>
          </span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="admin-menu">
          <li>
            <a class="dropdown-item" routerLink="admin/gateway" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="road" [fixedWidth]="true"></fa-icon>
              <span>Gateway</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="admin/user-management" routerLinkActive="active"
              (click)="collapseNavbar()">
              <fa-icon icon="users" [fixedWidth]="true"></fa-icon>
              <span>User management</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="admin/metrics" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="tachometer-alt" [fixedWidth]="true"></fa-icon>
              <span>Metrics</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="admin/health" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="heart" [fixedWidth]="true"></fa-icon>
              <span>Health</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="admin/configuration" routerLinkActive="active"
              (click)="collapseNavbar()">
              <fa-icon icon="cogs" [fixedWidth]="true"></fa-icon>
              <span>Configuration</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="admin/logs" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="tasks" [fixedWidth]="true"></fa-icon>
              <span>Logs</span>
            </a>
          </li>
          <li *ngIf="openAPIEnabled">
            <a class="dropdown-item" routerLink="admin/docs" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="book" [fixedWidth]="true"></fa-icon>
              <span>API</span>
            </a>
          </li>
          <!-- jhipster-needle-add-element-to-admin-menu - JHipster will add entities to the admin menu here -->
        </ul>
      </li>
      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngSwitchCase="false">
        <a class="nav-link" (click)="login()" id="login">
          <fa-icon icon="sign-in-alt" [fixedWidth]="true"></fa-icon>
            <span>Se connecter</span>
        </a>
      </li>
      <li ngbDropdown class="nav-item dropdown pointer" display="dynamic" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }" *ngSwitchCase="true">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu"
          data-cy="accountMenu">
          <span *ngIf="!getImageUrl()">
            <fa-icon icon="user"></fa-icon>
            <span>Compte</span>
          </span>
          <span *ngIf="getImageUrl()">
            <img [src]="getImageUrl()" class="profile-image rounded-circle" alt="Avatar" />
          </span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="account-menu"
          *jhiHasAnyAuthority="['ROLE_ADMIN', 'ROLE_USER']">
          <li *ngSwitchCase="true">
            <a class="dropdown-item" routerLink="account/settings" routerLinkActive="active" (click)="collapseNavbar()"
              data-cy="settings">
              <fa-icon icon="wrench" [fixedWidth]="true"></fa-icon>
              <span>Param&egrave;tres</span>
            </a>
          </li>
          <li *ngSwitchCase="true">
            <a class="dropdown-item" routerLink="account/password" routerLinkActive="active" (click)="collapseNavbar()"
              data-cy="passwordItem">
              <fa-icon icon="lock" [fixedWidth]="true"></fa-icon>
              <span>Mot de passe</span>
            </a>
          </li>
          <li *ngSwitchCase="true">
            <a class="dropdown-item" (click)="logout()" id="logout" data-cy="logout">
              <fa-icon icon="sign-out-alt" [fixedWidth]="true"></fa-icon>
              <span>Se d&eacute;connecter</span>
            </a>
          </li>
          <!-- <li *ngSwitchCase="false">
            <a class="dropdown-item" (click)="login()" id="login" data-cy="login">
              <fa-icon icon="sign-in-alt" [fixedWidth]="true"></fa-icon>
              <span>Se connecter</span>
            </a>
          </li> -->
        </ul>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="account-menu"
          *jhiHasAnyAuthority="['ADMIN_AGENCE', 'ADMIN_ONG', 'CONTROLEUR_AGENCE', 'SUPERVISEUR_AGENCE', 'SUPERVISEUR_ONG', 'CONSULTATION', 'PHONE_CALL_ENTRE_EST', 'PHONE_CALL_ENTRE_OUEST']">
          <li *ngSwitchCase="true">
            <a class="dropdown-item" routerLink="account/password" routerLinkActive="active" (click)="collapseNavbar()"
              data-cy="passwordItem">
              <fa-icon icon="lock" [fixedWidth]="true"></fa-icon>
              <span>Mot de passe</span>
            </a>
          </li>
          <li *ngSwitchCase="true">
            <a class="dropdown-item" (click)="logout()" id="logout" data-cy="logout">
              <fa-icon icon="sign-out-alt" [fixedWidth]="true"></fa-icon>
              <span>Se d&eacute;connecter</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>