import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'region',
        data: { pageTitle: 'Regions' },
        loadChildren: () => import('./ciblagegeo/region/region.module').then(m => m.CiblagegeoRegionModule),
      },
      {
        path: 'province',
        data: { pageTitle: 'Provinces' },
        loadChildren: () => import('./ciblagegeo/province/province.module').then(m => m.CiblagegeoProvinceModule),
      },
      {
        path: 'commune',
        data: { pageTitle: 'Communes' },
        loadChildren: () => import('./ciblagegeo/commune/commune.module').then(m => m.CiblagegeoCommuneModule),
      },
      {
        path: 'localite',
        data: { pageTitle: 'Localites' },
        loadChildren: () => import('./ciblagegeo/localite/localite.module').then(m => m.CiblagegeoLocaliteModule),
      },
      {
        path: 'cartepauvrete-com',
        data: { pageTitle: 'Import. communes' },
        loadChildren: () =>
          import('./ciblagegeo/carte-pauvrete-commune/carte-pauvrete-commune.module').then(m => m.CartePauvreteCommuneModule),
      },
      {
        path: 'cartepauvrete-local',
        data: { pageTitle: 'Import. localités' },
        loadChildren: () =>
          import('./ciblagegeo/carte-pauvrete-localite/carte-pauvrete-localite.module').then(m => m.CartePauvreteLocaliteModule),
      },
      {
        path: 'importcomitedepart',
        data: { pageTitle: 'Import. comité départemenatal' },
        loadChildren: () =>
          import('./ciblagegeo/import-comite-depart/import-comite-depart.module').then(m => m.ImportComiteDeparmentalModule),
      },
      {
        path: 'nature-piece-identite',
        data: { pageTitle: 'NaturePieceIdentites' },
        loadChildren: () =>
          import('./ciblagegeo/nature-piece-identite/nature-piece-identite.module').then(m => m.CiblagegeoNaturePieceIdentiteModule),
      },
      {
        path: 'session-departementale',
        data: { pageTitle: 'SessionDepartementales' },
        loadChildren: () =>
          import('./ciblagegeo/session-departementale/session-departementale.module').then(m => m.CiblagegeoSessionDepartementaleModule),
      },
      {
        path: 'comite-departemental',
        data: { pageTitle: 'ComiteDepartementals' },
        loadChildren: () =>
          import('./ciblagegeo/comite-departemental/comite-departemental.module').then(m => m.CiblagegeoComiteDepartementalModule),
      },
      {
        path: 'membre-comite',
        data: { pageTitle: 'MembreComites' },
        loadChildren: () => import('./ciblagegeo/membre-comite/membre-comite.module').then(m => m.CiblagegeoMembreComiteModule),
      },
      {
        path: 'agence-collecte',
        data: { pageTitle: 'AgenceCollectes' },
        loadChildren: () => import('./ciblagegeo/agence-collecte/agence-collecte.module').then(m => m.CiblagegeoAgenceCollecteModule),
      },
      {
        path: 'controleur',
        data: { pageTitle: 'Controleurs' },
        loadChildren: () => import('./ciblagegeo/controleur/controleur.module').then(m => m.CiblagegeoControleurModule),
      },
      {
        path: 'equipe-enquete',
        data: { pageTitle: 'EquipeEnquetes' },
        loadChildren: () => import('./ciblagecom/equipe-enquete/equipe-enquete.module').then(m => m.CiblagegeoEquipeEnqueteModule),
      },
      {
        path: 'enqueteur',
        data: { pageTitle: 'Enqueteurs' },
        loadChildren: () => import('./ciblagegeo/enqueteur/enqueteur.module').then(m => m.CiblagegeoEnqueteurModule),
      },
      {
        path: 'role-enqueteur',
        data: { pageTitle: 'RoleEnqueteurs' },
        loadChildren: () => import('./ciblagegeo/role-enqueteur/role-enqueteur.module').then(m => m.CiblagegeoRoleEnqueteurModule),
      },
      {
        path: 'importation-excel',
        data: { pageTitle: 'ImportationExcels' },
        loadChildren: () => import('./ciblagegeo/importation-excel/importation-excel.module').then(m => m.CiblagegeoImportationExcelModule),
      },
      {
        path: 'zone-denombrement',
        data: { pageTitle: 'ZoneDenombrements' },
        loadChildren: () => import('./ciblagegeo/zone-denombrement/zone-denombrement.module').then(m => m.CiblagegeoZoneDenombrementModule),
      },
      {
        path: 'assemblee-generale',
        data: { pageTitle: 'AssembleeGenerales' },
        loadChildren: () =>
          import('./ciblagegeo/assemblee-generale/assemblee-generale.module').then(m => m.CiblagegeoAssembleeGeneraleModule),
      },
      {
        path: 'critere-pauvrete',
        data: { pageTitle: 'CriterePauvretes' },
        loadChildren: () => import('./ciblagegeo/critere-pauvrete/critere-pauvrete.module').then(m => m.CiblagegeoCriterePauvreteModule),
      },
      {
        path: 'menage-preidentifie',
        data: { pageTitle: 'MenagePreidentifies' },
        loadChildren: () =>
          import('./ciblagegeo/menage-preidentifie/menage-preidentifie.module').then(m => m.CiblagegeoMenagePreidentifieModule),
      },
      {
        path: 'importciblagecom',
        data: { pageTitle: 'ImportCiblageCommunautaire' },
        loadChildren: () => import('./ciblagecom/import/import-ciblage-com.module').then(m => m.ImportCiblageComModule),
      },
      {
        path: 'ciblagecom',
        data: { pageTitle: 'CiblageCommunautaire' },
        loadChildren: () => import('./ciblagecom/ciblage-com.module').then(m => m.CiblageComModule),
      },
      {
        path: 'role-animateur',
        data: { pageTitle: 'RoleAnimateurs' },
        loadChildren: () => import('./ciblagegeo/role-animateur/role-animateur.module').then(m => m.CiblagegeoRoleAnimateurModule),
      },
      {
        path: 'creationzd',
        data: { pageTitle: 'Creation ZD' },
        loadChildren: () => import('./ciblagegeo/creationzd/creation-zd.module').then(m => m.CreationZdModule),
      },
      {
        path: 'reclamation',
        data: { pageTitle: 'Gestion reclamation' },
        loadChildren: () => import('./ciblagegeo/reclamation/reclamation.module').then(m => m.CiblagegeoReclamationModule),
      },
      {
        path: 'ong',
        data: { pageTitle: 'ONG' },
        loadChildren: () => import('./ciblagegeo/ong/ong.module').then(m => m.CiblagegeoOngModule),
      },
      {
        path: 'animateur',
        data: { pageTitle: 'Animateur' },
        loadChildren: () => import('./ciblagegeo/animateur/animateur.module').then(m => m.CiblagegeoAnimateurModule),
      },
      {
        path: 'equipe-animateur',
        data: { pageTitle: 'Animateur' },
        loadChildren: () => import('./ciblagegeo/equipe-animateur/equipe-animateur.module').then(m => m.CiblagegeoEquipeAnimateurModule),
      },
      {
        path: 'template',
        data: { pageTitle: 'Template file' },
        loadChildren: () => import('./ciblagegeo/template/template.module').then(m => m.CiblagegeoTemplateModule),
      },
      {
        path: 'export-canevas-comite-depart',
        data: { pageTitle: 'Export canevas' },
        loadChildren: () => import('./ciblagegeo/export-cavenas-comite-depart/export-canevas-comite-depart.module').then(m => m.ExportCanevasComiteDepartModule),
      },
      {
        path: 'superviseur-ong',
        data: { pageTitle: 'Superviseur ONG' },
        loadChildren: () => import('./ciblagegeo/superviseur-ong/superviseur-ong.module').then(m => m.CiblagegeoSuperviseurOngModule),
      },
      {
        path: 'superviseur-agence',
        data: { pageTitle: 'Superviseur Agence' },
        loadChildren: () => import('./ciblagecom/superviseur-agence/superviseur-agence.module').then(m => m.CiblagegeoSuperviseurAgenceModule),
      },
      {
        path: 'menage-enquete',
        data: { pageTitle: 'Menages enquetees' },
        loadChildren: () => import('./collectedonnees/collecte-donnees.module').then(m => m.CollecteDonneesModule),
      },
      {
        path: 'variable-pmt',
        data: { pageTitle: 'Variable PMT' },
        loadChildren: () => import('./scorepmt/variable-pmt/variable-pmt.module').then(m => m.ScorepmtVariablePmtModule),
      },
      {
        path: 'type-decoupage',
        data: { pageTitle: 'Type de découpage' },
        loadChildren: () => import('./scorepmt/type-decoupage/type-decoupage.module').then(m => m.ScorepmtTypeDecoupageModule),
      },
      {
        path: 'modele-pmt',
        data: { pageTitle: 'Modèle PMT' },
        loadChildren: () => import('./scorepmt/modele-pmt/modele-pmt.module').then(m => m.ScorepmtModelePmtModule),
      },
      {
        path: 'formule-pmt',
        data: { pageTitle: 'Formule PMT' },
        loadChildren: () => import('./scorepmt/formule-pmt/formule-pmt.module').then(m => m.ScorepmtFormulePmtModule),
      },
      {
        path: 'calcul-pmt',
        data: { pageTitle: 'Calcul score PMT & classement' },
        loadChildren: () => import('./scorepmt/calcul-score-pmt/calcul-score-pmt.module').then(m => m.ScorepmtCalculPmtModule),
      },
      {
        path: 'zone-controle',
        data: { pageTitle: 'Zone controle' },
        loadChildren: () => import('./ciblagegeo/zone-controle/zone-controle.module').then(m => m.CiblagegeoZoneControleModule),
      },
      {
        path: 'zone-decoupage',
        data: { pageTitle: 'Zone de decoupage' },
        loadChildren: () => import('./ciblagegeo/zone-decoupage/zone-decoupage.module').then(m => m.CiblagegeoZoneDecoupageModule),
      },
      {
        path: 'menage-phone-call',
        data: { pageTitle: 'Ménage phone call' },
        loadChildren: () => import('./phonecall/menage-phone-call.module').then(m => m.MenagePhoneCallModule),
      },
      {
        path: 'enumeration',
        data: { pageTitle: 'Enumération' },
        loadChildren: () => import('./enumeration/menage-preidentifie-enum.module').then(m => m.EnumerationModule),
      },
      {
        path: 'denombrement',
        data: { pageTitle: 'Dénombrement' },
        loadChildren: () => import('./denombrement/menages-denombrement.module').then(m => m.DenombrementModule),
      },
      {
        path: 'apurement',
        data: { pageTitle: 'Apurement' },
        loadChildren: () => import('./apurement/apurement.module').then(m => m.ApurementModule),
      },
      {
        path: 'utilisateur-apli-plainte',
        data: { pageTitle: 'Utilisateurs apli plainte' },
        loadChildren: () => import('./ciblagegeo/utilisateur-apli-plainte/utilisateur-apli-plainte.module').then(m => m.CiblagegeoUtilisateurApliPlainteModule),
      }
    ]),
  ],
})
export class EntityRoutingModule {}
