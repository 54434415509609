import { IProvince } from "../province/province.model";

export interface IAgenceCollecte {
  id?: number;
  raisonSociale?: string;
  telAgence?: string;
  emailAgence?: string | null;
  adresseAgence?: string | null;
  nomRepondant?: string;
  prenomRepondant?: string;
  actifDenombrement?: string;
  actifCollecteDonnes?: string;
  typePassage?: string;
  milieuOperation?: string;
}

export class AgenceCollecte implements IAgenceCollecte {
  constructor(
    public id?: number,
    public raisonSociale?: string,
    public telAgence?: string,
    public emailAgence?: string | null,
    public adresseAgence?: string | null,
    public nomRepondant?: string,
    public prenomRepondant?: string,
    public actifDenombrement?: string,
    public actifCollecteDonnes?: string,
    public typePassage?: string,
    public milieuOperation?: string
  ) { }
}

export function getAgenceCollecteIdentifier(agenceCollecte: IAgenceCollecte): number | undefined {
  return agenceCollecte.id;
}

export interface IProvinceAgenceDto {
  agenceCollecte?: IAgenceCollecte;
  provinces?: IProvince[];
}

export class ProvinceAgenceDto {
  constructor(
    public agenceCollecte?: IAgenceCollecte,
    public provinces?: IProvince[],
  ) { }
}

export interface IProvinceAgencePk {
  idOng?: number;
  idProvince?: number;
}

export class ProvinceAgencePk {
  constructor(
    public idOng?: number,
    public idProvince?: number
  ) { }
}
