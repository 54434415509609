<div class="row justify-content-center">
  <div class="col-8">
    <div *jhiHasAnyAuthority="['ADMIN_AGENCE', 'SUPERVISEUR_AGENCE', 'CONTROLEUR_AGENCE']">

      <div>
        <h3>Bienvenue {{ account?.firstName }} {{ account?.lastName }} !</h3>
        <br />
        Vous êtes sur l'espace reservé à votre agence : <b style="font-size: 14pt;">[{{ agence?.raisonSociale }}]</b>
      </div>
      <br />

      <div>
        <h5><b>Provinces couvertes par votre agence</b></h5>
        <div *ngIf="provinces.length===0">
          <span style="color: crimson;">Veuillez demander aux administrateurs de SIG de configurer les provinces pour
            votre agence</span>
        </div>
        <div *ngIf="provinces && provinces.length>0">
          <table class="table table-striped" aria-describedby="page-heading" style="width: 100%;">
            <thead>
              <tr>
                <th scope="col"><span>R&eacute;gion</span></th>
                <th scope="col"><span>Province</span></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let province of provinces" data-cy="entityTable">
                <td>{{ province.region!.nomRegion }}</td>
                <td>{{ province.nomProvince }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>

    <div *jhiHasAnyAuthority="['ADMIN_ONG', 'SUPERVISEUR_ONG']">

      <div>
        <h3>Bienvenue {{ account?.firstName }} {{ account?.lastName }} !</h3>
        <br />
        Vous êtes sur l'espace reservé à votre ONG : <b style="font-size: 14pt;">[{{ ong?.raisonSociale }}]</b>
      </div>
      <br />

      <div>
        <h5><b>Provinces couvertes par votre ONG</b></h5>
        <div *ngIf="provinces.length===0">
          <span style="color: crimson;">Veuillez demander aux administrateurs de SIG de configurer les provinces pour
            votre ONG</span>
        </div>
        <div *ngIf="provinces && provinces.length>0">
          <table class="table table-striped" aria-describedby="page-heading" style="width: 100%;">
            <thead>
              <tr>
                <th scope="col"><span>R&eacute;gion</span></th>
                <th scope="col"><span>Province</span></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let province of provinces" data-cy="entityTable">
                <td>{{ province.region!.nomRegion }}</td>
                <td>{{ province.nomProvince }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>

    <div *jhiHasAnyAuthority="'ROLE_USER'" class="col-md-9" style="height: 500px;">

    </div>
  </div>
</div>