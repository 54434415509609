import { IProvince } from "../province/province.model";

export interface IOng {
  id?: number;
  raisonSociale?: string;
  telOng?: string;
  emailOng?: string | null;
  adresseOng?: string | null;
  nomRepresentant?: string;
  prenomRepresentant?: string;
  tel1Representant?: string;
  tel2Representant?: string | null;
}

export class Ong implements IOng {
  constructor(
    public id?: number,
    public raisonSociale?: string,
    public telOng?: string,
    public emailOng?: string | null,
    public adresseOng?: string | null,
    public nomRepresentant?: string,
    public prenomRepresentant?: string,
    public tel1Representant?: string,
    public tel2Representant?: string | null
  ) {}
}

export function getOngIdentifier(ong: IOng): number | undefined {
  return ong.id;
}

export interface IProvinceOngDto{
  ong?: IOng;
  provinces?: IProvince[];
}

export class ProvinceOngDto{
  constructor(
    public ong?: IOng,
    public provinces?: IProvince[],
  ){ }
}
