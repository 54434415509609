import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IAgenceCollecte, getAgenceCollecteIdentifier, IProvinceAgenceDto, IProvinceAgencePk } from '../agence-collecte.model';
import { IProvince } from '../../province/province.model';

export type EntityResponseType = HttpResponse<IAgenceCollecte>;
export type EntityArrayResponseType = HttpResponse<IAgenceCollecte[]>;

@Injectable({ providedIn: 'root' })
export class AgenceCollecteService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/agence-collectes', 'ciblagegeo');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) { }

  create(agenceCollecte: IAgenceCollecte): Observable<EntityResponseType> {
    return this.http.post<IAgenceCollecte>(this.resourceUrl, agenceCollecte, { observe: 'response' });
  }

  affecterProvincesAAgence(provinceOngDto: IProvinceAgenceDto): Observable<EntityResponseType> {

    const url = this.applicationConfigService.getEndpointFor('api/affecter-provinces-agence', 'ciblagegeo');
    return this.http.post<IAgenceCollecte>(url, provinceOngDto, { observe: 'response' });
  }

  update(agenceCollecte: IAgenceCollecte): Observable<EntityResponseType> {
    return this.http.put<IAgenceCollecte>(`${this.resourceUrl}/${getAgenceCollecteIdentifier(agenceCollecte) as number}`, agenceCollecte, {
      observe: 'response',
    });
  }

  partialUpdate(agenceCollecte: IAgenceCollecte): Observable<EntityResponseType> {
    return this.http.patch<IAgenceCollecte>(
      `${this.resourceUrl}/${getAgenceCollecteIdentifier(agenceCollecte) as number}`,
      agenceCollecte,
      { observe: 'response' }
    );
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IAgenceCollecte>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IAgenceCollecte[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  getAllProvincesGroupByAgence(): Observable<HttpResponse<IProvinceAgenceDto[]>> {
    const url = this.applicationConfigService.getEndpointFor('api/provinces-affectees-par-agences', 'ciblagegeo');
    return this.http.get<IProvinceAgenceDto[]>(url, { observe: 'response' });
  }

  getAllProvincesByAgence(idAgence: number): Observable<HttpResponse<IProvince[]>> {
    const url = this.applicationConfigService.getEndpointFor('api/provinces-affectees-par-agence', 'ciblagegeo');
    return this.http.get<IProvince[]>(url, { params: { idAgence: `${idAgence}` }, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }
  
  deleteAllProvinceAffecteesAgence(provinceOngDto: IProvinceAgenceDto): Observable<HttpResponse<{}>> {
    const url = this.applicationConfigService.getEndpointFor('api/delete-allprovince-agence', 'ciblagegeo');
    return this.http.post(url, provinceOngDto, {observe: 'response' });
  }

  deleteProvinceAffecteeAgence(provinceOngPk: IProvinceAgencePk): Observable<HttpResponse<{}>> {
    const url = this.applicationConfigService.getEndpointFor('api/delete-province-agence', 'ciblagegeo');
    return this.http.post(url, provinceOngPk, {observe: 'response' });
  }

  addAgenceCollecteToCollectionIfMissing(
    agenceCollecteCollection: IAgenceCollecte[],
    ...agenceCollectesToCheck: (IAgenceCollecte | null | undefined)[]
  ): IAgenceCollecte[] {
    const agenceCollectes: IAgenceCollecte[] = agenceCollectesToCheck.filter(isPresent);
    if (agenceCollectes.length > 0) {
      const agenceCollecteCollectionIdentifiers = agenceCollecteCollection.map(
        agenceCollecteItem => getAgenceCollecteIdentifier(agenceCollecteItem)!
      );
      const agenceCollectesToAdd = agenceCollectes.filter(agenceCollecteItem => {
        const agenceCollecteIdentifier = getAgenceCollecteIdentifier(agenceCollecteItem);
        if (agenceCollecteIdentifier == null || agenceCollecteCollectionIdentifiers.includes(agenceCollecteIdentifier)) {
          return false;
        }
        agenceCollecteCollectionIdentifiers.push(agenceCollecteIdentifier);
        return true;
      });
      return [...agenceCollectesToAdd, ...agenceCollecteCollection];
    }
    return agenceCollecteCollection;
  }
}
