import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISectionInconherence } from 'app/entities/collectedonnees/collecte-donnees.model';


@Component({
  templateUrl: './incoherences-donnees.component.html',
})
export class IncoherencesDonneesComponent {
  isLoading!: boolean;
  sections?: ISectionInconherence[];
  sectionTout: ISectionInconherence = {
    numero: -1,
    titre: "Tout",
    incoherences: []
  };
  sectionsAChoisir: ISectionInconherence[] = [{
    numero: -1,
    titre: "Tout",
    incoherences: []
  }];
  sectionCtrl = new FormControl();

  constructor(
    public activeModal: NgbActiveModal,
    protected activatedRoute: ActivatedRoute
  ) { }

  cancel(): void {
    this.activeModal.dismiss();
  }

  retour(): void {
    this.activeModal.close('confirm');
  }

  previousState(): void {
    window.history.back();
  }

  trackSectionById(index: number, item: ISectionInconherence): number {
    return item.numero!;
  }

  sectionSelected(): void {
    const section: ISectionInconherence = this.sectionCtrl.value;
    if (section.numero === -1) {
      this.sections = [...this.sectionsAChoisir.slice(1)];// Prendre les sections à partir de 1
    } else {
      this.sections = [section];
    }
  }

}
