import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AccountService } from 'app/core/auth/account.service';
import { Account } from 'app/core/auth/account.model';
import { IOng } from 'app/entities/ciblagegeo/ong/ong.model';
import { OngService } from 'app/entities/ciblagegeo/ong/service/ong.service';
import { IProvince } from 'app/entities/ciblagegeo/province/province.model';
import { ICommune } from 'app/entities/ciblagegeo/commune/commune.model';
import { IAgenceCollecte } from 'app/entities/ciblagegeo/agence-collecte/agence-collecte.model';
import { AgenceCollecteService } from 'app/entities/ciblagegeo/agence-collecte/service/agence-collecte.service';

@Component({
  selector: 'jhi-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  account: Account | null = null;
  authSubscription?: Subscription;
  ong?: IOng;
  agence?: IAgenceCollecte;
  provinces: IProvince[] = [];
  communes: ICommune[] = [];

  constructor(
    private accountService: AccountService,
    private ongService: OngService,
    private agenceService: AgenceCollecteService,
    private router: Router) { }

  ngOnInit(): void {
    this.authSubscription = this.accountService.getAuthenticationState().subscribe(account => {
      this.account = account;
      if (account!.idOng) {
        this.ongService.find(account!.idOng).subscribe(res => this.ong = res.body!);
        this.ongService.getAllProvinceByOng(account!.idOng).subscribe(res => this.provinces = res.body!);
      }
      if (account!.idAgence) {
        this.agenceService.find(account!.idAgence).subscribe(res => this.agence = res.body!);
        this.agenceService.getAllProvincesByAgence(account!.idAgence).subscribe(res => this.provinces = res.body!);
      }
      return account;
    });
  }

  isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }

  login(): void {
    this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
