<form name="detailForm" (ngSubmit)="retour()">
  <div class="modal-header">
    <h4 class="modal-title">Incohérences des données</h4>

    <button type="button" class="btn btn-primary" data-dismiss="modal" aria-hidden="true"
      (click)="retour()">Retour</button>
  </div>

  <div class="modal-body">
    <jhi-alert-error></jhi-alert-error>

    <div class="spinner-border text-secondary" role="status" *ngIf="isLoading">
      <span class="sr-only">Loading...</span>
    </div>

    <div class="col-md-5">
      <label class="form-control-label" for="field_section">Section</label>
      <select class="form-control" id="field_section" data-cy="section" name="section" [formControl]="sectionCtrl"
        (change)="sectionSelected()">
        <option *ngIf="!sectionCtrl.value" [ngValue]="null" selected>--Choisir une section--</option>
        <option [ngValue]="sectionOption.numero === sectionCtrl.value?.numero ? sectionCtrl.value : sectionOption"
          *ngFor="let sectionOption of sectionsAChoisir; trackBy: trackSectionById">
          {{ sectionOption.titre }}
        </option>
      </select>
    </div><br />

    <div *ngIf="sections && sections.length === 0" class="navbar bg-primary">
      <span class="navbar-title" style="color: white;">
        Il semble qu'il n'y a pas d'incohérences !
      </span>
    </div>

    <div *ngFor="let section of sections">
      <div class="navbar bg-primary">
        <span class="navbar-title" style="color: white;">
          {{ section.titre }}
        </span>
      </div>
      <div class="row-md" *ngFor="let incoherence of section.incoherences" style="margin-bottom: 2px;">
        <dt>
          <span>{{ incoherence.variable}}</span>
        </dt>
        <dd>
          <span style="color: crimson; font-weight: bold;">{{ incoherence.messageErreur}}</span>
        </dd><br/>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">
      <span>Retour</span>
    </button>
  </div>
</form>
