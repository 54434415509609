import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IOng, getOngIdentifier, IProvinceOngDto } from '../ong.model';
import { IProvince, IProvinceOngPk } from '../../province/province.model';

export type EntityResponseType = HttpResponse<IOng>;
export type EntityArrayResponseType = HttpResponse<IOng[]>;

@Injectable({ providedIn: 'root' })
export class OngService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/ongs', 'ciblagegeo');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) { }

  create(ong: IOng): Observable<EntityResponseType> {
    return this.http.post<IOng>(this.resourceUrl, ong, { observe: 'response' });
  }

  affecterProvincesAOng(provinceOngDto: IProvinceOngDto): Observable<EntityResponseType> {

    const url = this.applicationConfigService.getEndpointFor('api/affecter-provinces-ong', 'ciblagegeo');
    return this.http.post<IOng>(url, provinceOngDto, { observe: 'response' });
  }

  update(ong: IOng): Observable<EntityResponseType> {
    return this.http.put<IOng>(`${this.resourceUrl}/${getOngIdentifier(ong) as number}`, ong, { observe: 'response' });
  }

  partialUpdate(ong: IOng): Observable<EntityResponseType> {
    return this.http.patch<IOng>(`${this.resourceUrl}/${getOngIdentifier(ong) as number}`, ong, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IOng>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IOng[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  getAllProvincesGroupByOng(): Observable<HttpResponse<IProvinceOngDto[]>> {
    const url = this.applicationConfigService.getEndpointFor('api/provinces-affectees-par-ongs', 'ciblagegeo');
    return this.http.get<IProvinceOngDto[]>(url, { observe: 'response' });
  }

  getAllProvinceByOng(idOng: number): Observable<HttpResponse<IProvince[]>> {
    const url = this.applicationConfigService.getEndpointFor('api/provinces-par-ong', 'ciblagegeo');
    return this.http.get<IProvince[]>(url, { params: { idOng: `${idOng}` }, observe: 'response' });
  }

  deleteAllProvinceAffecteesOng(provinceOngDto: IProvinceOngDto): Observable<HttpResponse<{}>> {
    const url = this.applicationConfigService.getEndpointFor('api/delete-allprovince-ong', 'ciblagegeo');
    return this.http.post(url, provinceOngDto, {observe: 'response' });
  }

  deleteProvinceAffecteeOng(provinceOngPk: IProvinceOngPk): Observable<HttpResponse<{}>> {
    const url = this.applicationConfigService.getEndpointFor('api/delete-province-ong', 'ciblagegeo');
    return this.http.post(url, provinceOngPk, {observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addOngToCollectionIfMissing(ongCollection: IOng[], ...ongsToCheck: (IOng | null | undefined)[]): IOng[] {
    const ongs: IOng[] = ongsToCheck.filter(isPresent);
    if (ongs.length > 0) {
      const ongCollectionIdentifiers = ongCollection.map(ongItem => getOngIdentifier(ongItem)!);
      const ongsToAdd = ongs.filter(ongItem => {
        const ongIdentifier = getOngIdentifier(ongItem);
        if (ongIdentifier == null || ongCollectionIdentifiers.includes(ongIdentifier)) {
          return false;
        }
        ongCollectionIdentifiers.push(ongIdentifier);
        return true;
      });
      return [...ongsToAdd, ...ongCollection];
    }
    return ongCollection;
  }
}
