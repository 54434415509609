<!--jhi-page-ribbon></jhi-page-ribbon-->

<div>
  <router-outlet name="navbar"></router-outlet>
</div>

<div class="container-fluid">
  <div class="card jh-card">
    <router-outlet></router-outlet>
  </div>

  <jhi-footer></jhi-footer>
</div>
